import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import '../main.scss'
import locales from '../constants/locales'

class PageNotFound extends Component {    
    render() {
        const { location: { pathname } } = this.props;

        let locale = 'en';
        let homeUrl = '/';
        if(pathname.includes('it')) {
            locale = 'it'
            homeUrl = '/it';
        }

        const { 
            TITLE,
            DESCRIPTION,
            KEYWORDS,

            PAGE_NOT_FOUND_BODY,
            PAGE_NOT_FOUND_BUTTON
        } = locales[locale].textEntries

        return (
            <div>
                <Helmet>
                    <title>{ TITLE }</title>
                    <meta name="description" content={ DESCRIPTION } />
                    <meta name="keywords" content={ KEYWORDS } />
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="content-language" content={ locale }/>
                    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://dreamy-davinci-1cb3af.netlify.com"/>
                    <meta property="og:title" content={ TITLE }/>
                    <meta property="og:image" content="https://dreamy-davinci-1cb3af.netlify.com/static/logo-fec4f77e2c2c6664328c7788991caa59.png"/>
                    <meta property="og:description" content={ DESCRIPTION }/>
                    <meta property="og:locale" content={ locale + '_' + locale.toUpperCase() }/>
                </Helmet>
                <div className="PageNotFoundContainer">
                    <div className="Message">
                        { PAGE_NOT_FOUND_BODY } 
                        <strong>{':('}</strong>
                        <div className="Button" onClick={() => navigate(homeUrl)}>
                            { PAGE_NOT_FOUND_BUTTON } 
                        </div>
                    </div>
                    <div className="MonitorDisplay">
                        <div className="glitch" data-text="404">
                            <span className="glitch__color glitch__color--red"><span>404</span></span>
                            <span className="glitch__color glitch__color--blue"><span>404</span></span>
                            <span className="glitch__color glitch__color--green"><span>404</span></span>
                            <span className="glitch__main">404</span>
                            <span className="glitch__line glitch__line--first"></span>
                            <span className="glitch__line glitch__line--second"></span>
                        </div>
                    </div>
                    
                    <div className="FooterDisplay"></div>
                    <div className="PlateDisplay"></div>
                </div> 
                          
            </div>
        )
    }
}

export default PageNotFound